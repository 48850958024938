import { Component, OnInit } from '@angular/core';
import {FileEndPointObject} from '../utils/file-end-point-object';
import {SingleFileConverterComponent} from '../single-file-converter/single-file-converter.component';
import {Bookobject} from '../book/bookobject';
import {Fileworker} from '../fileworker/fileworker';

@Component({
  selector: 'app-excel-date-converter',
  templateUrl: './excel-date-converter.component.html',
  styleUrls: ['./excel-date-converter.component.sass']
})
export class ExcelDateConverterComponent implements OnInit, SingleFileConverterComponent {

  latestBook: Bookobject;
  worker: Fileworker;

  constructor() { }

  ngOnInit() {
  }

  handleCapture(data: FileEndPointObject) {
    if (!!data.error) { return alert(data.error.message); }
  }

}
