import {Component, Input, OnInit} from '@angular/core';
import {FileConverterComponent} from '../file-converter/file-converter.component';
import {FileEndPointObject} from '../utils/file-end-point-object';
import {getSuperClassDeclarations} from '@angular/core/schematics/migrations/static-queries/angular/super_class';
import {Bookobject} from '../book/bookobject';

export class ElementInArray<T> {
  constructor(public index: number, public value?: T) {}
}

// export class BookInArray implements ElementInArray<Bookobject> {}

export const checkBooksForDuplicates = async (book: Bookobject, from: Bookobject[]): Promise<ElementInArray<Bookobject>> => {
  let index = 0;
  const result = await from.filter((e) => {
    index++;
    return e.name === book.name;
  });
  const present = result.length === 1;
  return present ? new ElementInArray(index, result[0]) : new ElementInArray(-1, null);
};

@Component({
  selector: 'app-multiple-file-converter',
  templateUrl: './multiple-file-converter.component.html',
  styleUrls: ['./multiple-file-converter.component.sass']
})
export class MultipleFileConverterComponent implements OnInit, FileConverterComponent {

  fileLimit: 10;
  @Input() books: Bookobject[] = [];

  constructor() { }

  ngOnInit() {
  }

  handleCapture(data: FileEndPointObject) {
    if (!!data.error) {

      return;
    }
    checkBooksForDuplicates(data.data, this.books)
        .then((result) => {
          if (result.index > -1) {
            this.books[result.index] = data.data;
          } else {
            return this.books.push(data.data);
          }
        })
        .catch(err => console.log(err));
  }
}
