export const shouldTreatAsNumber = (cell): boolean => {
    return !RegExp('(/|,|.|,|, )').test(cell.w);
}

export const shouldTreatAsCurrency = (cell): boolean => {
    const one = /^[1-9]\d*(((,\d{3}){1})?(\.\d{0,2})?)$/.test(cell.w);
    const two = (/(kr|£|€)/.test(cell.w)
        || cell.w.indexOf('$') > -1);
    return one
        || two;
};

export const copyCellAsIsIfConvertingOnlyDates = (cell): boolean => {
    return cell.t !== 'n' || shouldTreatAsNumber(cell);
};
