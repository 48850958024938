import {Component, EventEmitter, HostListener, Input, OnInit, Output} from '@angular/core';
import * as XLSX from 'xlsx';
import {Bookobject} from '../book/bookobject';
import {getFileNameBasedOnFileSource} from '../utils/strings';
import {ErrorObject} from '../utils/error';
import {Observable} from 'rxjs';
import {FileEndPointObject} from '../utils/file-end-point-object';

export type AOA = any[][];

@Component({
  selector: 'app-sheetjs',
  templateUrl: './sheetjs.component.html',
  styleUrls: ['./sheetjs.component.sass']
})
export class SheetjsComponent implements OnInit {

  @Input() data: AOA[] = [];
  wopts: XLSX.WritingOptions = { bookType: 'xlsx'/*, type: 'array'*/};
  books: Bookobject[] = [];
  fileName: string;
  keys: string[] = [];
  error: string;
  dragAreaClass: string;
  @Output() fileEndPoint = new EventEmitter<FileEndPointObject>();

  constructor() {
    this.fileName = 'SheetJs';
  }

  ngOnInit() {
  }

  /*parseFileToBook(target: FileList) {
    const me = this;
    // if (target.files.length !== 1) { throw new Error('Cannot use multiple files'); }
    const fileNames = {};
    const books: Bookobject[] = [];
    let index = 0;
    const files: File[] = [];
    let errors;
    while (index < target.length) {
      fileNames[index] = target[index].name;
      files.push(target[index]);
      index++;
    }
    const execs: Promise<void>[] = [];
    files.forEach((file, i) => {
      execs.push(new Promise<void>((result, reject) => {
        const reader: FileReader = new FileReader();
        reader.onload = (e: any) => {
          const bstr: string = e.target.result;
          const wb: XLSX.WorkBook = XLSX.read(bstr, {type: 'binary'});
          const items = file.name.split('.');
          if (items[items.length - 1] !== 'xlsx') {
            if (!!errors) {
              errors = `${file.name} must be a .xlsx Excel Workbook`;
            } else {
              errors.append(`\\\\n\\\\n${file.name} must be a .xlsx Excel Workbook`);
            }
          } else {
            books.push(new Bookobject(wb, file.name, items[items.length - 1]));
          }
          return result();
        };
        reader.readAsBinaryString(file);
      }));
    });
    Promise.all(execs)
        .then(() => {
          if (!!errors) { return this.fileEndPoint.emit(
              new FileEndPointObject(null, new ErrorObject(errors, 'FileUploadError'))
          ); }
          return this.fileEndPoint.emit(new FileEndPointObject(books));
        })
        .catch(err => this.fileEndPoint.emit(new FileEndPointObject(null, new ErrorObject(err, 'FileUploadError'))));
  }*/

  onFileChange(evt: any) {
    // this.parseFileToBook(evt.target.files);
  }
  @HostListener('dragover', ['$event']) onDragOver(event: any) {
    this.dragAreaClass = 'droparea';
    event.preventDefault();
  }
  @HostListener('dragenter', ['$event']) onDragEnter(event: any) {
    this.dragAreaClass = 'droparea';
    event.preventDefault();
  }
  @HostListener('dragend', ['$event']) onDragEnd(event: any) {
    this.dragAreaClass = 'dragarea';
    event.preventDefault();
  }
  @HostListener('dragleave', ['$event']) onDragLeave(event: any) {
    this.dragAreaClass = 'dragarea';
    event.preventDefault();
  }
  @HostListener('drop', ['$event']) onDrop(event: any) {
    this.dragAreaClass = 'dragarea';
    event.preventDefault();
    event.stopPropagation();
    const target: DataTransfer = (event.dataTransfer) as DataTransfer;
    // this.parseFileToBook(target.files);
  }
  saveFiles(files: FileList) {

    if (files.length > 1) { this.error = 'Only one file at time allow'; } else {
      this.error = '';
    }
  }
  /*onFileChangeSave(evt: any) {
    const me = this;
    const target: DataTransfer = (evt.target) as DataTransfer;
    if (target.files.length !== 1) {
      throw new Error('Cannot use multiple files');
    }
    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, {type: 'binary'});
      me.books.push(wb);
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];
      me.keys = Object.keys(ws);
      console.log(Object.keys(ws));
      me.keys.splice(me.keys.indexOf('!ref'), 1);
      me.keys.splice(me.keys.indexOf('!margins'), 1);
    };
    reader.readAsBinaryString(target.files[0]);
  }*//*
  async siftThroughABook(index: number): Promise<void> {
    const me = this;
    if (index > me.books.length - 1) { return; }
    const book = me.books[index];
    const sheets = book.SheetNames;
    const siftThroughAPage = async (sheet, name) => {
    }
  }*//*
  switchToDateStrings(index: number): void {
    const me = this;
    if (index > me.books.length - 1) { return; }
    const book = me.books[index];
    const wsname: string = book.SheetNames[0];
    const sheet = book.Sheets[wsname];
    const changeCellToDateString = (index: string, value: any) => {
      sheet[index] = {t: 's', v: `${value.y}-${value.m}-${value.d}`};
    };
    me.keys.forEach((value) => {
      const cell = sheet[value];
      if (!cell) {
        return;
      }
      try {
        const date = XLSX.SSF.parse_date_code(cell.v);
        if (date.D === 0 && date.y === 1900) { throw Error('Error - Not a Date'); }
        console.log('Is a Date');
        changeCellToDateString(value, date);
      } catch (e) {
        console.log('Not a Date', value);
      }
      console.log(sheet);
    });
  }*//*
  exportSheet(index: number): void {
    if (index > this.books.length - 1) { return; }
    const book = this.books[index];
    const wsname: string = book.SheetNames[0];
    const sheet = book.Sheets[wsname];
    console.log(sheet);
    XLSX.writeFile(book, `${this.fileName}.xlsx`, this.wopts);
  }*//*
  export(): void {
    if (this.data.length === 0) {
      return console.log('Upload a file first!');
    }
    if (!this.fileName) {
      return console.log('Invalid - No FileName');
    }
    const gen = this.data[0];
    const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(gen);
    //generate workbook and add the worksheet
    //const wb: XLSX.WorkBook = XLSX.utils.book_new();
    //XLSX.utils.book_append_sheet(wb, ws, 'data');
    //console.log('Writing ...');
    //save to file
    //XLSX.writeFile(wb, `${this.fileName}.xlsx`, this.wopts);
  }*/

}
