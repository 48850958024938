import {Bookobject} from '../../book/bookobject';
import * as XLSX from 'xlsx';

export interface CellCoords {
    row: number;
    col: string;
}

export const cellCoords = (cellString: string): CellCoords => {
    const rowcord = cellString.match('[0-9]{1,10000}$');
    if (!!rowcord) {
        const row = rowcord[0];
        const col = cellString.replace(row, '');
        return { row: Number(row), col} as CellCoords;
    } else {
        throw new Error('Invalid Cell Address detected');
    }
};

export interface ExcelWorksheet {
    ['!ref']: string;
    ['!margin']: string;
}

export const crawlWorkBook = (book: Bookobject, sheetExec: (worksheet: XLSX.WorkSheet, name: string) => void) => {
    return book.book.SheetNames.forEach((sheet, index) => sheetExec(book.book.Sheets[sheet] as XLSX.WorkSheet, sheet));
};

export const performOnSheets = (book: Bookobject, sheetFunction: (worksheet: XLSX.WorkSheet, name: string) => void) => {
    return crawlWorkBook(book, sheetFunction);
};
