import {Component, OnInit, AfterViewInit, HostListener, ElementRef} from '@angular/core';
import {SCREEN_SIZE} from '../utils/screens';
import {ResizeService} from '../utils/resize';

@Component({
  selector: 'app-size-detector',
  templateUrl: './size-detector.component.html',
  styleUrls: ['./size-detector.component.sass']
})
export class SizeDetectorComponent implements AfterViewInit {

  sizes = [
    {
      id: SCREEN_SIZE.XS, name: 'xs', css: ``
    },
    {
      id: SCREEN_SIZE.SM, name: 'sm', css: ``
    },
    {
      id: SCREEN_SIZE.MD, name: 'md', css: ``
    },
    {
      id: SCREEN_SIZE.LG, name: 'lg', css: ``
    },
    {
      id: SCREEN_SIZE.XL, name: 'xl', css: ``
    }
  ];

  constructor(private elementRef: ElementRef, private resizeSvc: ResizeService) { }

  @HostListener('window:resize', [])
  private onResize() {
    this.detectScreenSize();
  }

  ngAfterViewInit() {
    this.detectScreenSize();
  }

  private detectScreenSize() {

    const currentSize = this.sizes.find(x => {
      // get the HTML element
      const el = this.elementRef.nativeElement.querySelector(`.${x.id}`);

      return window.getComputedStyle(el).display !== 'none';
    });

    this.resizeSvc.onResize(currentSize.id);
  }

}
