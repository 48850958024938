import {Component, EventEmitter, HostListener, Input, OnInit, Output, AfterViewInit, ViewChild} from '@angular/core';
import * as XLSX from 'xlsx';
import {Bookobject} from '../book/bookobject';
import {getFileNameBasedOnFileSource} from '../utils/strings';
import {ErrorObject} from '../utils/error';
import {FileEndPointObject} from '../utils/file-end-point-object';
import {SpinnerComponent} from '../spinner/spinner.component';


@Component({
  selector: 'app-filebox',
  templateUrl: './filebox.component.html',
  styleUrls: ['./filebox.component.sass']
})
export class FileboxComponent implements OnInit, AfterViewInit {


  @ViewChild(SpinnerComponent, {static: false}) spinner: SpinnerComponent;
  @Input() dragAreaClass = 'dragarea';
  @Input() browserContent = '';
  showThisSpinner = false;
  @Input() showSpinner = 'cell wrapper spinner spinner3 mobile-hide default-red';
  @Input() fileLimit: number;
  @Output() fileEndPoint = new EventEmitter<FileEndPointObject>();
  @Output() fileBoxNotes: string[] = [
      'The workbook will not perist any styling', 'The downloaded file will have the same name as your upload'
  ];

  constructor() { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.detectScreenSize();
  }

  detectScreenSize(): void {

  }

  testSpinner(): void {
    // this.showSpinner = this.showThisSpinner ? 'cell wrapper spinner spinner3 mobile-hide' : 'cell wrapper spinner spinner3 default-red';
    this.showThisSpinner = !this.showThisSpinner;
    this.spinner.toggle();
  }

  dragUploadText(): string {
    return `Drop ${this.fileLimit > 1 ? 'one or multiple files' : 'a File Here'}`;
  }

  uploadText(): string {
    return `Select ${this.fileLimit > 1 ? 'Files' : 'a File'}`;
  }

  @Output() autoDownloadText(): string {
    return 'Once converted, the workbook will download automatically';
  }

  parseFileToBook(target: FileList) {
    if (!!this.fileLimit && this.fileLimit < target.length) {
      return this.fileEndPoint.emit(
          new FileEndPointObject(null, new ErrorObject(`You can only upload ${this.fileLimit} file at a time!`, 'FileUploadError'))
      );
    }
    this.testSpinner();
    const fileNames = {};
    // const books: Bookobject[] = [];
    let index = 0;
    const files: File[] = [];
    let errors;
    while (index < target.length) {
      fileNames[index] = target[index].name;
      files.push(target[index]);
      index++;
    }
    const execs: Promise<void>[] = [];
    files.forEach((file, i) => {
      execs.push(new Promise<void>((result, reject) => {
        const reader: FileReader = new FileReader();
        reader.onload = (e: any) => {
          /* read workbook */
          const bstr: string = e.target.result;
          const wb: XLSX.WorkBook = XLSX.read(bstr, {type: 'binary', cellNF: true, cellDates: true});
          const items = file.name.split('.');
          if (items[items.length - 1] !== 'xlsx') {
            if (!!errors) {
              errors = `${file.name} must be a .xlsx Excel Workbook`;
            } else {
              errors.append(`\\n\\n${file.name} must be a .xlsx Excel Workbook`);
            }
          } else {
            this.fileEndPoint.emit(new FileEndPointObject(new Bookobject(wb, file.name, items[items.length - 1])));
          }
          return result();
        };
        reader.readAsBinaryString(file);
      }));
    });
    const stopSpinner = () => {
      this.testSpinner();
    };
    Promise.all(execs)
        .then(() => {
          stopSpinner();
          if (!!errors) { return this.fileEndPoint.emit(
              new FileEndPointObject(null, new ErrorObject(errors, 'FileUploadError'))
          ); }
        })
        .catch((err) => {
          stopSpinner();
          this.fileEndPoint.emit(new FileEndPointObject(null, new ErrorObject(err, 'FileUploadError')));
        });
  }

  onFileChange(evt: any) {
    this.parseFileToBook(evt.target.files);
  }
  @HostListener('dragover', ['$event']) onDragOver(event: any) {
    this.dragAreaClass = 'widebox dragarea drop';
    event.preventDefault();
  }
  @HostListener('dragenter', ['$event']) onDragEnter(event: any) {
    this.dragAreaClass = 'widebox dragarea drop';
    event.preventDefault();
  }
  @HostListener('dragend', ['$event']) onDragEnd(event: any) {
    this.dragAreaClass = 'widebox dragarea drop';
    event.preventDefault();
  }
  @HostListener('dragleave', ['$event']) onDragLeave(event: any) {
    this.dragAreaClass = 'widebox dragarea';
    event.preventDefault();
  }
  @HostListener('drop', ['$event']) onDrop(event: any) {
    this.dragAreaClass = 'widebox dragarea';
    event.preventDefault();
    event.stopPropagation();
    const target: DataTransfer = (event.dataTransfer) as DataTransfer;
    this.parseFileToBook(target.files);
  }

  /*@HostListener('window:resize', ['$event']) onResize(event: any) {
    this.browserContent = event.target.innerWidth > 500 ? '' : 'mobile-hide';
  }*/

}
