import {Bookobject} from '../book/bookobject';
import {performExcelDateConversion} from './excel/dateconverter';

export enum FileWorkType {
    excel_date_conversion
}
export class FileWorkExecutionError {
    constructor(public error: string) {}
}

export interface IgnoreHeaderOptions {
    row: number;
}

export class FileWorkExecutionOptions {
    ignoreHeaders: IgnoreHeaderOptions;
    resultFormat: string;
    constructor() {}
}
export class FileWorkExecution {
    constructor(public type: FileWorkType, public options: FileWorkExecutionOptions) {}
}

export class FileWorkHistoryLog {
    doneAt: Date;
    constructor(public file: string, public message: string) {
        this.doneAt = new Date();
    }
}


export class Fileworker {

    public history: FileWorkHistoryLog[] = [];

    constructor() {
    }

    async performWork(on: Bookobject, exec: FileWorkExecution): Promise<Bookobject> {
        switch (exec.type) {
            case FileWorkType.excel_date_conversion: return await performExcelDateConversion(on, exec.options);
            default: return Promise.reject(new FileWorkExecutionError(`${exec.type} is not a supported conversion type`));
        }
    }

}
