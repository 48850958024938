import { Component, OnInit } from '@angular/core';
import { AOA } from './sheetjs/sheetjs.component';

type UserFile = any[];

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['../usecases/golstyles.sass', './app.component.sass']
})
export class AppComponent implements OnInit {
  title = 'toolbox';
  userFiles: UserFile = [];
  indexes: number[] = [1, 2, 3, 4, 5, 6, 7];

  ngOnInit(): void {
    this.userFiles = [[['1', '2'], ['A', 'B']]] as AOA;
  }
}
