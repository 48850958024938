import {Component, Input, OnInit} from '@angular/core';
// <div [class]="showSpinner"></div>

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.sass']
})
export class SpinnerComponent implements OnInit {

  displaySpinner = false;
  @Input() showSpinner = 'cell wrapper spinner spinner3';
  @Input() opacity = '_1';
  @Input() centered = false;

  constructor() { }

  ngOnInit() {
    //this.assignClass();
  }

  private assignClass(): void {
    this.showSpinner = this.displaySpinner ? `cell wrapper spinner spinner3 ${this.opacity} mobile-hide` : `cell wrapper spinner spinner3 ${this.opacity}`;
  }
  toggle(): void {
    //this.assignClass();
    this.displaySpinner = !this.displaySpinner;
  }
}
