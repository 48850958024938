import * as XLSX from 'xlsx';

export class Bookobject {
    wopts: XLSX.WritingOptions = { bookType: 'xlsx'/*, type: 'array'*/};
    constructor(public book?: XLSX.WorkBook, public name?: string, public fileType?: string) {
        if (!!fileType) { return; }
        const items = name.split('.');
        this.fileType = items[items.length - 1];
    }
    export(opts?: XLSX.WritingOptions): void {
        console.log(this.book);
        XLSX.writeFile(this.book, `${this.name}.${this.fileType}`/*, !!opts ? opts : this.wopts*/);
    }
}

