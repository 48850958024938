import {Bookobject} from '../../book/bookobject';
import * as XLSX from 'xlsx';
import {cellCoords, performOnSheets} from './workbookcrawler';
import {FileWorkExecutionOptions} from '../fileworker';
import {AOA} from '../../sheetjs/sheetjs.component';
import {copyCellAsIsIfConvertingOnlyDates, shouldTreatAsCurrency, shouldTreatAsNumber} from '../../utils/cellcheckers';

const alphabet = [...Array(26).keys()].map(i => String.fromCharCode(i + 97).toUpperCase());

const columnNumberFrom = (value: string): number => {
    const letter = value.split('');
    const arr: number[] = [];
    for (const i of letter) {
        const ind = alphabet.indexOf(i);
        arr.push(ind);
    }
    return arr.reduce((pres, curr) => pres + curr);
};

const changeCellToDateString = (sheet: any, index: string, value: any) => {
    sheet[index] = {t: 's', v: `${value.y}-${value.m}-${value.d}`};
};

const changeCellToDateStringWitC = (sheet: any, row: number, col: number, value: any) => {
    const rowIndex = row - 1;
    const rowLine = sheet[rowIndex];
    rowLine[col] = {t: 's', v: `${value.y}-${value.m}-${value.d}`};
};

const checkIfRowExistsThenAdd = (sheet: AOA, index: number) => {
    if (!sheet[index]) { sheet[index] = []; }
};

export const excelDateConverterSheetCrawler = (book: Bookobject,
                                               newBook: Bookobject,
                                               sheet: XLSX.WorkSheet,
                                               name: string,
                                               options?: FileWorkExecutionOptions) => {
    console.log('Handling Sheet:', sheet);
    const thisSheet = [[]] as AOA;
    const keys = Object.keys(sheet);
    keys.splice(keys.indexOf('!ref'), 1);
    keys.splice(keys.indexOf('!margins'), 1);
    const handleEachKey = (value) => {
        const cell = sheet[value];
        if (!cell) { return; }
        const coords = cellCoords(value);
        checkIfRowExistsThenAdd(thisSheet, coords.row - 1);
        const ignoreHeaderOptions = !!options && !!options.ignoreHeaders && options.ignoreHeaders.row === coords.row;
        const treatAsNumber = shouldTreatAsNumber(cell);
        const treatAsCurrency = shouldTreatAsCurrency(cell);
        if (ignoreHeaderOptions || treatAsCurrency || treatAsNumber) {
            if (treatAsCurrency) {
                cell.z = '"$"#,##0.00';
            }
            thisSheet[coords.row - 1].push(cell);
            return;
        }
        let date;
        if (cell.t === 'd') {
            date = {m: cell.v.getMonth() + 1, d: cell.v.getDate(), y: cell.v.getFullYear()};
        } else {
            date = XLSX.SSF.parse_date_code(cell.v);
            if (date.D === 0 && date.y === 1900) {
                thisSheet[coords.row - 1].push(cell);
                return; // throw Error('Error - Not a Date');
            }
        }
        const colu = columnNumberFrom(coords.col);
        changeCellToDateStringWitC(thisSheet, coords.row, colu, date);
    };
    keys.forEach(handleEachKey);
    XLSX.utils.book_append_sheet(newBook.book, XLSX.utils.aoa_to_sheet(thisSheet), name);
};

export const performExcelDateConversion = async (book: Bookobject, options?: FileWorkExecutionOptions): Promise<Bookobject> => {
    const newBook = new Bookobject(null, book.name, null);
    newBook.book = XLSX.utils.book_new();
    try {
        await performOnSheets(book, (sheet, name) => excelDateConverterSheetCrawler(book, newBook, sheet, name, options));
        return newBook;
    } catch (e) {
        throw e;
    }
};
