import { Component, OnInit } from '@angular/core';
import {FileEndPointObject} from '../utils/file-end-point-object';

@Component({
  selector: 'app-file-converter',
  templateUrl: './file-converter.component.html',
  styleUrls: ['./file-converter.component.sass']
})
export class FileConverterComponent implements OnInit {

  fileLimit: 10;

  constructor() { }

  ngOnInit() {
  }

  handleCapture(data: FileEndPointObject) {
    if (!!data.error) { return alert(data.error.message); }
  }

}
