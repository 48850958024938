import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SheetjsComponent } from './sheetjs/sheetjs.component';
import { BookComponent } from './book/book.component';
import { FileboxComponent } from './filebox/filebox.component';
import { SingleFileConverterComponent } from './single-file-converter/single-file-converter.component';
import { MultipleFileConverterComponent } from './multiple-file-converter/multiple-file-converter.component';
import { FileConverterComponent } from './file-converter/file-converter.component';
import { HeaderComponent } from './header/header.component';
import { SizeDetectorComponent } from './size-detector/size-detector.component';
import { ExcelDateConverterComponent } from './excel-date-converter/excel-date-converter.component';
import { SpinnerComponent } from './spinner/spinner.component';

@NgModule({
  declarations: [
    AppComponent,
    SheetjsComponent,
    BookComponent,
    FileboxComponent,
    SingleFileConverterComponent,
    MultipleFileConverterComponent,
    FileConverterComponent,
    HeaderComponent,
    SizeDetectorComponent,
    ExcelDateConverterComponent,
    SpinnerComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
