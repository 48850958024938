import {Component, Input, OnInit} from '@angular/core';
import * as XLSX from 'xlsx';
import {AOA} from '../sheetjs/sheetjs.component';
import {Bookobject} from './bookobject';

@Component({
  selector: 'app-book',
  templateUrl: './book.component.html',
  styleUrls: ['./book.component.sass']
})
export class BookComponent implements OnInit {

  @Input() data: AOA[] = [];
  wopts: XLSX.WritingOptions = { bookType: 'xlsx'/*, type: 'array'*/};
  @Input() book: Bookobject;
  keys: string[] = [];
  addedAt: Date;

  constructor() {
    this.addedAt = new Date();
  }

  ngOnInit() {
    console.log('My book name is', this.book.name);
  }

}

