import {Component, OnInit} from '@angular/core';
import {Bookobject} from '../book/bookobject';
import {FileEndPointObject} from '../utils/file-end-point-object';
import {Fileworker, FileWorkExecution, FileWorkExecutionOptions, FileWorkType, IgnoreHeaderOptions} from '../fileworker/fileworker';

@Component({
  selector: 'app-single-file-converter',
  templateUrl: './single-file-converter.component.html',
  styleUrls: ['../app.component.sass', './single-file-converter.component.sass']
})
export class SingleFileConverterComponent implements OnInit {

  latestBook: Bookobject;
  worker: Fileworker;

  constructor() {
  }

  ngOnInit() {
    this.worker = new Fileworker();
  }

  handleCapture(data: FileEndPointObject) {
    this.latestBook = data.data;
    if (!!data.error) { return alert(data.error.message); }
    // this.worker.performWork()
    const options = new FileWorkExecutionOptions();
    options.ignoreHeaders = { row: 1 };
    options.resultFormat = 'yyyy-mm-dd';
    this.worker.performWork(this.latestBook, new FileWorkExecution(FileWorkType.excel_date_conversion, options))
        .then((result) => {
          result.export();
        })
        .catch(err => alert(err));
  }

}
